import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBxbATmw8an8jcCOVUYqvR7iZUbKi5H0AU",
  authDomain: "the-react-tutorial-1.firebaseapp.com",
  projectId: "the-react-tutorial-1",
  storageBucket: "the-react-tutorial-1.appspot.com",
  messagingSenderId: "687718492995",
  appId: "1:687718492995:web:8795485aa9b08a0b885df6",
  measurementId: "G-2TTYZX526K"
};

firebase.initializeApp(firebaseConfig);