import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login  from "./pages/Login";
import "./App.css";
import PrivateRoute from "./PrivateRoute";
import Home from "./Home";

export default function App(){
  
  return(
    <Router>
        <Switch>
             <Route exact path="/"component={Login}/>
             <PrivateRoute exact path="/home"component={Home}/>
      </Switch>

    </Router>

  )
}