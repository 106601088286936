
import React from "react";
import Card from "@material-ui/core/Card"

import {Button} from '@material-ui/core';
import firebase from "firebase/app";

 const handleClickDelete= (id)=> {
    console.log(id);
    var db = firebase.firestore();
    db.collection("notes").doc(id).delete().then(() => {
      console.log("Document successfully deleted!");
  }).catch((error) => {
      console.error("Error removing document: ", error);
  });
    };
         
    

export default function Notes(props){
    const {notes}=props;
    if(!notes||notes.length===0){
        return(<p className="mt-5">You havent created any note</p>);
    }
    else{
        return(
            <>
            <div className="container">
                Your Notes
            </div>
            <div className="container mt-4">
                <div className="card-columns">
                    {
                        notes.map((note)=>{
                            return(
                                <Card key={note.id} className="card p-3">
                                    <h6 className="m-0">{note.note}</h6>
                                    <Button
                                 variant="contained"
                                 color="secondary"
                                 className="ml-auto mt-3 text-capitalize font-weight-bold"
                                 onClick={() => handleClickDelete(note.id)} //handleClickSucess
                               >
                                 Delete
                               </Button>
                                </Card> 

                                
                            );
                        })
                        
                    }
                   

                </div>
            </div>
            </>
        );
        
          
    }
}




